/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import Reviews from 'templates/reviews'

// eslint-disable-next-line react/jsx-props-no-spreading
const HomeTemplate = data => <Reviews {...data} />

export const pageQuery = graphql`
  {
    page: wordpressPage(wordpress_id: { eq: 120 }) {
      title
      path
      content

      acf {
        banner {
          title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default HomeTemplate